/*******************
 * Script Imports
 *******************/
import $ from "jquery";
window.$ = window.jQuery = $;

import "popper.js";
import "bootstrap";
import "slick-slider";
import "justifiedGallery";
import "lity"; 
// import "./../node_modules/bootstrap-table/dist/bootstrap-table.js";
import "./blocks/image-slider/image-slider";
import "./blocks/timeline/timelinr";
import "./blocks/tabs/tabs";
import "./blocks/horizontal-gallery/horizontal-gallery"; 

import "./vendor/vendor";
import "./utility/detect-ie";
import "./utility/is-touch-device";
import "./utility/acf-google-map";
import "./theme/scripts";
import "./theme/on-load.js";
import "./theme/on-scroll.js";
import "./theme/on-resize.js";

import "./blocks/tabs/select2.min.js";
/*******************
 * Style Imports
 *******************/
import "../scss/main.scss";
