jQuery(function() {
  console.log("Theme scripts working")

  // FLASH OF UNSTYLED CONTENT

  $("body").addClass("FOUC")

  // NAV FUNCTIONALITY

  function adjustNavSpace() {
    var navHeight = $(".navigation-bar").outerHeight()
    var navSpace = $(".nav-space")
    navSpace.outerHeight(navHeight)
  }
  adjustNavSpace()

  $(".page-navigation > .menu > li:has(ul)")
    .on("mouseenter", function() {
      // $('a', this).addClass('link-hover');
      $(this)
        .children("ul")
        .on("mouseenter", function() {
          $(this)
            .prev()
            .addClass("link-hover")
        })
      $(this)
        .children("ul")
        .on("mouseleave", function() {
          $(this)
            .prev()
            .removeClass("link-hover")
        })
      $(this)
        .children("ul")
        .css("display", "none")

      if ($(this).is(":last-child")) {
        $(this)
          .children("ul")
          .css("right", "0px")
          .fadeIn(150, "linear")
      } else {
        $(this)
          .children("ul")
          .css("left", "auto")
          .fadeIn(150, "linear")
      }
    })
    .on("mouseleave", function() {
      $("a", this).removeClass("link-hover")
      $(this)
        .children("ul")
        // .delay(150)
        .fadeOut(150, "linear", function() {
          $(this)
            .children("ul")
            .css("left", "-9999em")
        })
    })

  // SEARCH BUTTON

  $("button #search-icon").on("click", function(e) {
    if (!$(".searchform").hasClass("open")) {
      e.preventDefault()
    }
    $(".searchform").addClass("open")
    $("#search-icon").removeClass("search-bounce")
  })

  $(".search-close").on("click", function() {
    $(".searchform").removeClass("open")
  })

  // HAMBURGER MENU

  $(".hamburger-wrapper .hamburger-menu").on("click", function() {
    $(this).toggleClass("opened")
    $(this).attr("aria-expanded", $(this).hasClass("opened"))

    $(".mobile-menu").toggleClass("open")
    $(".menu-overlay").toggleClass("menu-overlay-active")
  })
  $(".menu-overlay").on("click", function() {
    $(".hamburger-wrapper .hamburger-menu").toggleClass("opened")
    $(".hamburger-wrapper .hamburger-menu").attr(
      "aria-expanded",
      $(this).hasClass("opened")
    )
    $(".mobile-menu").toggleClass("open")
    $(".menu-overlay").toggleClass("menu-overlay-active")
  })

  //MOBILE WITH NAV BANNER MENU
  adjustMenuTop()
  function adjustMenuTop() {
    if ($(".navigation-banner").length > 0) {
      let navBannerHeight = $(".navigation-banner").outerHeight()
      console.log(navBannerHeight)
      if ($(window).outerWidth() < 768) {
        $(".mobile-menu").css("top", navBannerHeight + 40 + "px")
      } else if ($(window).outerWidth() < 991) {
        $(".mobile-menu").css("top", navBannerHeight + 60 + "px")
      }
    }
  }
  $(window).on("resize", function() {
    adjustMenuTop()
  })

  //MOBILE MENU ACCORDION

  function initMobileMenu() {
    $(
      ".mobile-page-menu #menu-main-navigation .menu-item-has-children > span"
    ).addClass("with-chevron")
    $(
      ".mobile-page-menu #menu-main-navigation .current_page_ancestor > span"
    ).addClass("up")
    $(
      ".mobile-page-menu #menu-main-navigation .current_page_item > span"
    ).addClass("up")
    $(".mobile-page-menu #menu-main-navigation .sub-menu").hide()
    $(
      ".mobile-page-menu #menu-main-navigation .current_page_item > .sub-menu"
    ).show()

    $(".mobile-page-menu #menu-main-navigation li a").on("click", function() {
      var checkElement = $(this)
        .next()
        .next()

      if (checkElement.is("ul") && checkElement.is(":visible")) {
        // checkElement.slideUp("normal");
        window.location = $(this).attr("href")
        return false
      } else {
        $(this)
          .next()
          .toggleClass("up")
      }
      if (checkElement.is("ul") && !checkElement.is(":visible")) {
        $(".mobile-page-menu #menu-main-navigation ul:visible")
          .not(
            checkElement.parentsUntil(".mobile-page-menu #menu-main-navigation")
          )
          .slideUp("normal")

        $(".mobile-page-menu #menu-main-navigation ul:visible")
          .not(
            checkElement.parentsUntil(".mobile-page-menu #menu-main-navigation")
          )
          .prev()
          .toggleClass("up")

        checkElement.find("a").each(function() {
          if ($(this).hasClass("up")) {
            $(this).toggleClass("up")
          }
        })

        checkElement.slideDown("normal")
        return false
      }
    })
    $(".mobile-page-menu #menu-main-navigation li span").on(
      "click",
      function() {
        $(this).toggleClass("up")

        var checkElement = $(this).next()

        if (checkElement.is("ul") && checkElement.is(":visible")) {
          checkElement.slideUp("normal")
          // window.location = $(this).attr("href");
          return false
        }
        if (checkElement.is("ul") && !checkElement.is(":visible")) {
          $(".mobile-page-menu #menu-main-navigation ul:visible")
            .not(
              checkElement.parentsUntil(
                ".mobile-page-menu #menu-main-navigation"
              )
            )
            .slideUp("normal")

          $(".mobile-page-menu #menu-main-navigation ul:visible")
            .not(
              checkElement.parentsUntil(
                ".mobile-page-menu #menu-main-navigation"
              )
            )
            .prev()
            .toggleClass("up")

          checkElement.find("a").each(function() {
            if ($(this).hasClass("up")) {
              $(this).toggleClass("up")
            }
          })

          checkElement.slideDown("normal")
          return false
        }
      }
    )

    $(".mobile-page-menu #menu-main-navigation .current-menu-item")
      .parentsUntil(".mobile-page-menu #menu-main-navigation")
      .slideDown("normal")
    $(".mobile-page-menu #menu-main-navigation .current-page-ancestor")
      .parentsUntil(".mobile-page-menu #menu-main-navigation")
      .slideDown("normal")
  }

  $(function() {
    initMobileMenu()
  })

  //FORMIDABLE BUTTON SCRIPT

  $(".with_frm_style .frm_radio input[type=radio]").after("<span>") // Remove this line if you do not wish to style radio buttons
  $(".with_frm_style .frm_checkbox input[type=checkbox]").after("<span>") // Remove this line if you do not wish to style checkboxes

  $('#frm_checkbox_33-other_13 input[type="checkbox"]').on("click", function() {
    if ($(this).is(":checked")) {
      $('#frm_checkbox_33-other_13 > input[type="text"]').addClass("showOther")
    } else {
      $('#frm_checkbox_33-other_13 > input[type="text"]').removeClass(
        "showOther"
      )
    }
  })

  //FIRST THREE WORDS FUNCTION

  $(".first-words").html(function() {
    let text = this.innerHTML.trim(" ")
    let firstThreeWords = text
      .split(" ")
      .slice(0, 3)
      .join(" ")
    let lastWords = text
      .split(" ")
      .slice(3)
      .join(" ")
    return (
      `<span class='first-three-words'>` +
      firstThreeWords +
      `</span> ` +
      lastWords
    )
  })

  //   var columnHeights = $(".team-member-grid").map(function() {
  //     return $(this).height();
  // }).get(),

  // maxHeight = Math.max.apply(null, columnHeights);

  // $(".team-member-grid").height(maxHeight);

  // LOCATIONS MAP CONTAINER

  if ($("body").hasClass("single-location")) {
    $(".single-location-container").addClass("container-no-padding")
  }

  if ($("body").hasClass("single-person")) {
    $(".person-information-container").addClass("container-no-padding")
  }

  // MAP OVERLAY CLOSE

  $(".map-overlay-close").on("click", function() {
    if ($(".multi-location-map-overlay").hasClass("show-map-overlay")) {
      $(".multi-location-map-overlay").removeClass("show-map-overlay")
    }
  })

  // WITH-CHEVRON ACTIVE STATE FLIP FUNCTION

  $(".goodman-button").on("click", function() {
    $(".goodman-button")
      .not($(this))
      .find(".with-chevron")
      .removeClass("up")
    $(this)
      .find(".with-chevron")
      .toggleClass("up")
  })

  // PERSON / PAGE SIDE BAR FUNCTINALITY FOR ACCORDION

  $("#parent-cat-accordion > .collapse").each(function() {
    if ($(".person", this).hasClass("active")) {
      $(this).addClass("show")
      $(this)
        .prev()
        .attr("aria-expanded", "true")
      $(this)
        .prev()
        .find(".heading")
        .toggleClass("active")
      $(this)
        .prev()
        .find(".with-chevron")
        .toggleClass("up")
    }
  })
  $("#parent-cat-accordion > .parent-cat-heading > .with-chevron").each(
    function() {
      $(this).on("click", function(e) {
        if ($(this).hasClass("up")) {
          // e.stopImmediatePropagation();
          // window.location = $(this).attr("data-url");
          $(this).toggleClass("up")
          $(this)
            .prev()
            .toggleClass("active")
        } else {
          $("#parent-cat-accordion > .parent-cat-heading > .with-chevron").each(
            function() {
              if ($(this).hasClass("up")) {
                $(this).toggleClass("up")
              }
            }
          )
          $(this).toggleClass("up")
          $(this)
            .prev()
            .toggleClass("active")
        }
      })
    }
  )
  $("#parent-cat-accordion > .parent-cat-heading > .heading").each(function() {
    $(this).on("click", function(e) {
      if ($(this).hasClass("active")) {
        e.stopImmediatePropagation()
        window.location = $(this).attr("data-url")
        // $(this).toggleClass("up");
      } else {
        $("#parent-cat-accordion > .parent-cat-heading > .heading").each(
          function() {
            if ($(this).hasClass("active")) {
              $(this).toggleClass("active")
              $(this)
                .next()
                .toggleClass("up")
            }
          }
        )
        $(this).toggleClass("active")
        $(this)
          .next()
          .toggleClass("up")
      }
    })
  })

  $(".sub-cat-accordion > .collapse").each(function() {
    if ($(".person", this).hasClass("active")) {
      $(this).addClass("show")
      $(this)
        .prev()
        .attr("aria-expanded", "true")
      $(this)
        .prev()
        .find(".sub-heading")
        .toggleClass("active")
      $(this)
        .prev()
        .find(".with-chevron")
        .toggleClass("up")
    }
  })
  $(".sub-cat-accordion > .sub-cat-heading > .with-chevron").each(function() {
    $(this).on("click", function(e) {
      if ($(this).hasClass("up")) {
        // e.stopImmediatePropagation();
        // window.location = $(this).attr("data-url");
        $(this).toggleClass("up")
        $(this)
          .prev()
          .toggleClass("active")
      } else {
        $(".sub-cat-accordion > .sub-cat-heading > .with-chevron").each(
          function() {
            if ($(this).hasClass("up")) {
              $(this).toggleClass("up")
            }
          }
        )
        $(this).toggleClass("up")
        $(this)
          .prev()
          .toggleClass("active")
      }
    })
  })
  $(".sub-cat-accordion > .sub-cat-heading > .sub-heading").each(function() {
    $(this).on("click", function(e) {
      if ($(this).hasClass("active")) {
        e.stopImmediatePropagation()
        window.location = $(this).attr("data-url")
        // $(this).toggleClass("up");
      } else {
        let parent = $(this)
          .parent()
          .parent()
          .attr("id")
        $(".sub-cat-accordion > .sub-cat-heading > .sub-heading").each(
          function() {
            if (
              $(this).hasClass("active") &&
              $(this)
                .closest(".sub-cat-accordion")
                .attr("id") == parent
            ) {
              $(this).toggleClass("active")
              $(this)
                .next()
                .toggleClass("up")
            }
          }
        )
        $(this).toggleClass("active")
        $(this)
          .next()
          .toggleClass("up")
      }
    })
  })

  // PARENT PAGE SIDE BAR FUNCTINALITY FOR ACCORDION

  $("#parent-page-accordion > .collapse").each(function() {
    if ($(".grandchild-page", this).hasClass("active")) {
      $(this).addClass("show")
      $(this)
        .prev()
        .attr("aria-expanded", "true")
      $(this)
        .prev()
        .find(".heading")
        .toggleClass("active")
      $(this)
        .prev()
        .find(".with-chevron")
        .toggleClass("up")
    }
  })

  $("#parent-page-accordion > .parent-heading > .with-chevron").each(
    function() {
      $(this).on("click", function(e) {
        if ($(this).hasClass("up")) {
          $(this).toggleClass("up")
          $(this)
            .prev()
            .toggleClass("active")
        } else {
          $("#parent-page-accordion > .parent-heading > .with-chevron").each(
            function() {
              if ($(this).hasClass("up")) {
                $(this).toggleClass("up")
              }
            }
          )
          $(this).toggleClass("up")
          $(this)
            .prev()
            .toggleClass("active")
        }
      })
    }
  )

  $("#parent-page-accordion > .parent-heading > .heading").each(function() {
    $(this).on("click", function() {
      if ($(this).hasClass("single")) {
        window.location = $(this).attr("data-url")
      }
      if ($(this).hasClass("active")) {
        e.stopImmediatePropagation()
        window.location = $(this).attr("data-url")
        // $(this).toggleClass("up");
      } else {
        $("#parent-page-accordion > .parent-heading > .heading").each(
          function() {
            if ($(this).hasClass("active")) {
              $(this).toggleClass("active")
              $(this)
                .next()
                .toggleClass("up")
            }
          }
        )
        $(this).toggleClass("active")
        $(this)
          .next()
          .toggleClass("up")
      }
    })
  })

  $(".child-page-accordion > .collapse").each(function() {
    if ($(".grandchild-page", this).hasClass("active")) {
      $(this).addClass("show")
      $(this)
        .prev()
        .attr("aria-expanded", "true")
      $(this)
        .prev()
        .find(".sub-heading")
        .toggleClass("active")
      $(this)
        .prev()
        .find(".with-chevron")
        .toggleClass("up")
    }
  })
  $(".child-page-accordion > .child-heading > .with-chevron").each(function() {
    $(this).on("click", function(e) {
      if ($(this).hasClass("up")) {
        $(this).toggleClass("up")
        $(this)
          .prev()
          .toggleClass("active")
      } else {
        $(".child-page-accordion > .child-heading > .with-chevron").each(
          function() {
            if ($(this).hasClass("up")) {
              $(this).toggleClass("up")
            }
          }
        )
        $(this).toggleClass("up")
        $(this)
          .prev()
          .toggleClass("active")
      }
    })
  })
  $(".child-page-accordion > .child-heading > .sub-heading").each(function() {
    $(this).on("click", function(e) {
      if ($(this).hasClass("single")) {
        window.location = $(this).attr("data-url")
      }
      if ($(this).hasClass("active")) {
        e.stopImmediatePropagation()
        window.location = $(this).attr("data-url")
        // $(this).toggleClass("up");
      } else {
        let parent = $(this)
          .parent()
          .parent()
          .attr("id")

        $(".child-page-accordion > .child-heading > .sub-heading").each(
          function() {
            if (
              $(this).hasClass("active") &&
              $(this)
                .closest(".child-page-accordion")
                .attr("id") == parent
            ) {
              console.log("true")
              $(this).toggleClass("active")
              $(this)
                .next()
                .toggleClass("up")
            }
          }
        )
        $(this).toggleClass("active")
        $(this)
          .next()
          .toggleClass("up")
      }
    })
  })

  // COLOR FOR SPINE ON ACTIVE GRANDCHILD NODE <!!PAGE WITH SIDEBAR!!>

  function checkPageSideBar() {
    let sidebar = $(".parent-page-sidebar")
    if (sidebar.length) {
      if (
        $(".grandchild-page").hasClass("active") &&
        $(".grandchild-page.active")
          .attr("data-heading-parent")
          .includes("spine")
      ) {
        sidebar.addClass("background-teal")
      }
    }
  }
  checkPageSideBar()

  ///

  // ANCHOR LINK CALLBACK
  $(".anchored-list-callout a[href*=\\#]").on("click", function(event) {
    event.preventDefault()
    // var href = $.attr(this, 'href');
    $("html,body").animate({ scrollTop: $(this.hash).offset().top - 160 }, 500)
  })

  // ERIC TO ADD DEFINITION ANCHOR SCROLL HERE
  $(".glossary-breadcrumb a[href*=\\#]").on("click", function(event) {
    event.preventDefault()
    // var href = $.attr(this, 'href');
    $("html,body").animate({ scrollTop: $(this.hash).offset().top - 250 }, 500)
  })

  // SEARCH NO RESULTS PAGE BOUNCE

  if ($("#search-results").find("article#post-not-found").length !== 0) {
    $(".search-icon").addClass("search-bounce")
  }

  //BOOTSTRAP TOOLTIP INCLUSION
  $(".definition-tooltip").tooltip({
    boundary: "viewport",
    html: true,
    template:
      '<div class="tooltip definition-tooltip" role="tooltip"><div class="arrow definition-tooltip"></div><div class="tooltip-inner definition-tooltip"></div></div>',
    title: function() {
      let ttTitle = $(this).attr("data-title")
      let ttContent = $(this).attr("data-content")
      let ttElement = `<h4>${ttTitle}</h4><p>${ttContent}</p>`
      return ttElement
    },
  })

  //TOOL TIP FOR CUSTOM ILLUSTRATION
  var toolTipPlacement = ""
  if ($(window).outerWidth() < 991) {
    toolTipPlacement = "bottom"
  } else {
    toolTipPlacement = "right"
  }

  $(".custom-illustration-tooltip").tooltip({
    // trigger:'manual',
    boundary: "viewport",
    container: ".custom-illustration-section",
    html: true,
    placement: `${toolTipPlacement}`,
    template:
      '<div class="tooltip custom-illustration-tooltip-content" role="tooltip"><div class="arrow"></div><div class="tooltip-inner custom-illustration-content"></div></div>',
    title: function() {
      let title = $(this).attr("data-name")
      let content = $(this).attr("data-content")
      let element = `<h4>${title}</h4><p>${content}</p>`
      return element
    },
  })

  if ($(".custom-illustration-tooltip").hasClass("active")) {
    _this = $(".custom-illustration-tooltip.active")
    $(_this).tooltip("toggle")
    $(_this).on("mouseleave", function() {
      $(_this).removeClass("active")
    })
  }
  $(".custom-illustration-tooltip").on("mouseenter", function() {
    $(this).each(function() {
      if (!$(this).hasClass("active")) {
        $(this)
          .parent()
          .find(".active")
          .toggleClass("active")
          .tooltip("toggle")
      }
    })
  })

  // TIME LINE FUNCTIONALITY

  $("#timeline").timelinr({
    orientation: "horizontal",
    // value: horizontal | vertical, default to horizontal
    // containerDiv: '#timeline',
    // value: any HTML tag or #id, default to #timeline
    datesDiv: "#dates",
    // value: any HTML tag or #id, default to #dates
    datesSelectedClass: "selected",
    // value: any class, default to selected
    datesSpeed: "slow",
    // value: integer between 100 and 1000 (recommended) or 'slow', 'normal' or 'fast'; default to normal
    issuesDiv: "#issues",
    // value: any HTML tag or #id, default to #issues
    issuesSelectedClass: "selected",
    // value: any class, default to selected
    issuesSpeed: "normal",
    // value: integer between 100 and 1000 (recommended) or 'slow', 'normal' or 'fast'; default to fast
    issuesTransparency: 0.2,
    // value: integer between 0 and 1 (recommended), default to 0.2
    issuesTransparencySpeed: 500,
    // value: integer between 100 and 1000 (recommended), default to 500 (normal)
    prevButton: "#prev",
    // value: any HTML tag or #id, default to #prev
    nextButton: "#next",
    // value: any HTML tag or #id, default to #next
    arrowKeys: "true",
    // value: true/false, default to false
    startAt: 1,
    // value: integer, default to 1 (first)
    autoPlay: "false",
    // value: true | false, default to false
    autoPlayDirection: "forward",
    // value: forward | backward, default to forward
    autoPlayPause: 2000,
    // value: integer (1000 = 1 seg), default to 2000 (2segs)<
  })

  //hide all tabs first
  // $('.tab-content').hide();
  // //show the first tab content
  // $('#tab-1').show();

  var teamList = $("team-list")

  if ($(window).outerWidth() < 991) {
    $(".news-and-events-tabs > a").click(function(e) {
      console.log("true")
      if ($(this).hasClass("active")) {
        e.preventDefault()
        e.stopPropagation()
      }
    })
  }

  $(".tab-select").on("change", function() {
    dropdown = $(this).val()
    //first hide all tabs again when a new option is selected
    $(".tab-content .tab-pane")
      .hide()
      .removeClass("active")
    //then show the tab content of whatever option value was selected
    $("#" + dropdown)
      .show()
      .addClass("active")
  })

  $(".post-category-select").select2({
    theme: "bootstrap4",
    width: "100%",
    closeOnSelect: false,
    placeholder: "Filter by: Category",
    allowHtml: true,
  })

  $(".post-category-select").on("select2:close", function(e) {
    var pathArray = []
    var data = $(this).select2("data")
    data.map((selection) => {
      pathArray.push(selection.id)
    })
    var pathString = pathArray.join(",")
    console.log(pathString)
    if (pathString !== "") {
      window.location.href = "/category/" + pathString + "/"
    } else {
      window.location.href = "/news-events/"
    }
  })

  $(".post-sort-select").select2({
    theme: "bootstrap4",
    width: "100%",
    placeholder: "Sort by:",
  })

  $(".post-sort-select").on("select2:close", function(e) {
    var data = $(this).select2("data")
    var sortValue = data[0].id
    window.location.search = "?sort=" + sortValue
  })

  // TAB RESPONSIVE FUNCTIONALITY

  function navTabResponsive() {
    let navTabs = $(".nav-tabs > a")
    let height = 0
    if (navTabs.length) {
      if ($(window).outerWidth() <= 991) {
        navTabs.each(function() {
          height += $(this).outerHeight()
          if ($(this).hasClass("active")) {
            activeHeight = $(this).outerHeight()
            $(this).addClass("nav-order")
          }
          $(this)
            .off("click")
            .on("click", function(e) {
              if (
                $(this).hasClass("active") &&
                $(this)
                  .parent()
                  .hasClass("news-and-events-tabs")
              ) {
                e.preventDefault()
              }

              if (
                $(this).hasClass("active") &&
                !$(this)
                  .parent()
                  .hasClass("nav-open")
              ) {
                $(this)
                  .parent()
                  .addClass("nav-open")
              } else {
                if (
                  !$(this)
                    .parent()
                    .hasClass("news-and-events-tabs")
                ) {
                  navTabs.each(function() {
                    if ($(this).hasClass("nav-order")) {
                      $(this).removeClass("nav-order")
                    }
                  })
                  let tabHeight = $(this).outerHeight()
                  $(this)
                    .parent()
                    .css({
                      height: tabHeight,
                    })
                  $(this).addClass("nav-order")
                }

                $(this)
                  .parent()
                  .removeClass("nav-open")
              }
            })
        })
        navTabs.parent().css({
          height: activeHeight,
        })
      } else {
        navTabs.each(function() {
          $(this).removeClass("nav-order")
          $(this).unbind("click")
        })
        navTabs.parent().css({
          height: "unset",
        })
      }
    }
  }
  navTabResponsive()
  $(window).on("resize", function() {
    navTabResponsive()
  })
})
